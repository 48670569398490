import classNames from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  text: string
  href: string
  type?: 'primary' | 'secondary'
  className?: string
}
const Button = ({ text, href, type = 'primary', className }: IProps) => {
  const { t } = useTranslation()
  
  return (
    <a href={href} className={classNames(styles.button, className, styles[type])}>{t(text)}</a>
  )
}

export default Button