import * as React from "react"
import { useTranslation } from "react-i18next"
import { EmailIcon, InstagramIcon, TelegramIcon } from "../atoms/Icons"

import * as styles from './styles.module.scss'

const ContactsBlock = () => {
  const { t } = useTranslation()

  const contacts = [
    {
      address: 'telegram',
      link: 'https://t.me/',
      icon: <TelegramIcon />
    },
    {
      address: 'email',
      link: 'mailto:',
      icon: <EmailIcon />
    },
    {
      address: 'instagram',
      link: 'https://www.instagram.com/',
      icon: <InstagramIcon />
    }
  ]

  return (
    <div className={styles.contacts}>
      {contacts.map(contact => (
        <a href={`${contact.link}${t(contact.address)}`} className={styles.contact} target="_blank">
          {contact.icon}
          {t(contact.address)}
        </a>
      ))}
    </div>
  )
}

export default ContactsBlock