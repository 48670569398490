import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'

const ChooseBlock = () => {

  const cards = [
    {
      text: 'esports',
      icon: <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../../images/esports.png" alt="esports" />
    },
    {
      text: 'casino',
      icon: <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../../images/casino.png" alt="casino" />
    },
    {
      text: 'sport',
      icon: <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../../images/sport.png" alt="sport" />
    },
  ]

  return (
    <div className={styles.cards}>
      {cards.map(card => (
        <div className={styles.card}>
          <div className={styles.text}><Trans i18nKey={card.text} /></div>
          {card.icon}
        </div>
      ))}
    </div>
  )
}

export default ChooseBlock