import classNames from "classnames"
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  text: string
  href: string
  className?: string
}
const Link = ({ text, href, className }: IProps) => {
  const { t } = useTranslation()
  
  return (
    <a href={href} className={classNames(styles.link, className)}>{t(text)}</a>
  )
}

export default Link