import classNames from "classnames"
import { Trans } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { BankCardIcon, DateIcon, LocationIcon, ManagerIcon, MoneyIcon } from "../atoms/Icons"

import * as styles from './styles.module.scss'

const OurOfferBlock = () => {

  const cards = [
    {
      text: 'CPARevshare',
      icon: <BankCardIcon />
    },
    {
      text: 'GEOAsia',
      icon: <LocationIcon />
    },
    {
      text: 'personalManager',
      icon: <ManagerIcon />
    },
    {
      text: 'weeklyPayments',
      icon: <DateIcon />
    },
    {
      text: 'profitWithoutLimit',
      icon: <MoneyIcon />
    },
  ]

  return (
    <div className={styles.cards}>
      {cards.map(card => (
        <div className={styles.card}>
          <div className={styles.text}><Trans i18nKey={card.text} /></div>
          {card.icon}
        </div>
      ))}
    </div>
  )
}

export default OurOfferBlock