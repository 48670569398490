import * as React from 'react'
import { navigate } from "gatsby"

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  return (
    <svg
      onClick={() => navigate("/")}
      className={className}
      width="128"
      height="40"
      viewBox="0 0 128 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.3749 5.29691C66.9072 5.15544 67.5046 5.18597 67.9901 5.46078C68.6018 5.81396 69.009 6.50302 68.9988 7.21244C69.012 7.89233 68.6527 8.56307 68.0828 8.93254C67.6013 9.23686 66.9917 9.28368 66.4451 9.15951C65.583 8.95798 64.9723 8.08572 64.9927 7.21447C64.9784 6.37071 65.5504 5.52592 66.3749 5.29691Z"
        fill="white"
      />
      <path
        d="M80.2168 5.75104C81.4636 5.749 82.7104 5.749 83.9562 5.75104C83.9562 7.35307 83.9562 8.95613 83.9573 10.5582C85.1817 10.5622 86.4061 10.5531 87.6316 10.5633C89.4941 10.606 91.4127 11.0569 92.9242 12.1887C94.1995 13.1322 95.0718 14.5867 95.3282 16.151C95.6173 17.8966 95.3771 19.7897 94.3786 21.2828C93.4667 22.6834 91.9664 23.6126 90.3756 24.0533C89.3547 24.3434 88.288 24.4564 87.2285 24.4523C84.8916 24.4513 82.5537 24.4533 80.2168 24.4513C80.2168 18.2172 80.2168 11.9841 80.2168 5.75104ZM83.9613 12.4279C83.9501 15.8121 83.9593 19.1974 83.9562 22.5816C85.1165 22.5806 86.2768 22.5856 87.4372 22.5785C88.4967 22.5633 89.6081 22.206 90.3043 21.3704C91.2051 20.3047 91.4392 18.8503 91.4341 17.4956C91.4372 16.1398 91.198 14.6834 90.2891 13.6238C89.5929 12.7994 88.4906 12.4472 87.4402 12.432C86.2809 12.4248 85.1206 12.433 83.9613 12.4279Z"
        fill="#FFC42E"
      />
      <path
        d="M117.077 6.28545C118.323 6.28342 119.57 6.28443 120.816 6.28443C120.818 7.70937 120.815 9.13431 120.817 10.5592C122.197 10.5592 123.577 10.5572 124.956 10.5603C124.956 11.1832 124.956 11.805 124.956 12.4279C123.577 12.431 122.197 12.429 120.818 12.429C120.816 14.7628 120.816 17.0956 120.817 19.4295C120.819 20.3496 120.976 21.3165 121.524 22.0799C121.948 22.6712 122.64 23.0468 123.362 23.1038C124.438 23.1883 125.49 22.8178 126.427 22.319C126.695 22.89 126.949 23.4682 127.227 24.0351V24.0503C125.156 25.0763 122.676 25.2911 120.481 24.543C119.049 24.0554 117.849 22.8982 117.388 21.4478C116.998 20.3404 117.085 19.1547 117.077 18.0025C117.076 16.145 117.079 14.2875 117.076 12.429C116.231 12.4279 115.385 12.432 114.539 12.4269C114.54 11.805 114.539 11.1821 114.54 10.5603C115.385 10.5572 116.231 10.5603 117.077 10.5582C117.079 9.13431 117.076 7.70937 117.077 6.28545Z"
        fill="#FFC42E"
      />
      <path
        d="M100.07 11.9191C101.231 10.8606 102.764 10.2794 104.311 10.0993C106.193 9.87843 108.172 10.1298 109.849 11.0499C111.564 11.9843 112.834 13.7023 113.201 15.623C113.417 16.7273 113.312 17.8571 113.337 18.9746C109.535 18.9756 105.734 18.9746 101.932 18.9756C102.1 20.1217 102.484 21.3258 103.399 22.0973C104.433 22.9705 105.857 23.2128 107.172 23.0876C108.655 22.9695 110.025 22.2662 111.194 21.3807C111.545 21.9171 111.945 22.4403 112.248 22.997C111.304 23.8336 110.109 24.3375 108.897 24.6438C107 25.1344 104.975 25.0886 103.087 24.5777C101.446 24.1319 99.9006 23.1639 98.9784 21.7085C97.9321 20.1166 97.7 18.1258 97.9209 16.2683C98.1143 14.6336 98.8359 13.0306 100.07 11.9191ZM103.076 12.881C102.132 14.0627 101.881 15.6321 101.853 17.1039C104.345 17.1049 106.838 17.1049 109.332 17.1049C109.336 15.5507 109.001 13.8693 107.888 12.7151C106.629 11.398 104.225 11.4438 103.076 12.881Z"
        fill="#FFC42E"
      />
      <path
        d="M46.6958 5.7498C47.9416 5.74878 49.1874 5.75081 50.4332 5.74878C50.4362 7.35285 50.4332 8.9559 50.4352 10.559C52.5909 10.617 54.8016 10.3493 56.9003 10.9712C58.4729 11.4149 59.9528 12.3411 60.8556 13.7264C61.8693 15.2399 62.1034 17.1656 61.794 18.9325C61.5304 20.4297 60.7019 21.821 59.4958 22.7503C57.9548 23.9483 55.965 24.4144 54.0433 24.449C51.5945 24.4551 49.1446 24.4501 46.6958 24.4511C46.6947 18.218 46.6958 11.9839 46.6958 5.7498ZM50.4352 12.4287C50.4332 15.8129 50.4352 19.1971 50.4352 22.5813C51.6047 22.5793 52.7752 22.5864 53.9456 22.5783C54.9777 22.5559 56.0566 22.2119 56.7467 21.4109C57.678 20.3442 57.9182 18.8653 57.9131 17.4933C57.9161 16.1437 57.678 14.6943 56.7782 13.6348C56.0891 12.8114 54.993 12.4531 53.9467 12.4327C52.7762 12.4236 51.6057 12.4317 50.4352 12.4287Z"
        fill="white"
      />
      <path
        d="M72.7383 5.75049C73.9851 5.75049 75.2309 5.75049 76.4777 5.75049C76.4777 11.9846 76.4777 18.2187 76.4777 24.4518C75.2309 24.4528 73.9851 24.4528 72.7383 24.4518C72.7383 18.2187 72.7383 11.9846 72.7383 5.75049Z"
        fill="white"
      />
      <path
        d="M65.1211 10.836C66.3679 10.8106 67.6168 10.833 68.8646 10.8258C68.8646 15.3673 68.8666 19.9098 68.8646 24.4523C67.6178 24.4513 66.372 24.4523 65.1262 24.4513C65.1221 19.9129 65.1323 15.3744 65.1211 10.836Z"
        fill="white"
      />
      <path
        d="M50.3654 32.4157H47.8324L47.3331 33.6429H46.3633L48.643 28.3399H49.5693L51.8563 33.6429H50.872L50.3654 32.4157ZM50.0542 31.6429L49.0989 29.3248L48.1509 31.6429H50.0542Z"
        fill="white"
      />
      <path
        d="M53.4414 29.1657V30.7793H55.8875V31.6126H53.4414V33.6429H52.5005V28.3399H56.1987V29.1657H53.4414Z"
        fill="white"
      />
      <path
        d="M58.0494 29.1657V30.7793H60.4955V31.6126H58.0494V33.6429H57.1086V28.3399H60.8067V29.1657H58.0494Z"
        fill="white"
      />
      <path d="M61.7166 28.3399H62.6574V33.6429H61.7166V28.3399Z" fill="white" />
      <path d="M64.0277 28.3399H64.9685V32.8096H67.6173V33.6429H64.0277V28.3399Z" fill="white" />
      <path d="M68.3601 28.3399H69.3009V33.6429H68.3601V28.3399Z" fill="white" />
      <path
        d="M73.9568 32.4157H71.4238L70.9244 33.6429H69.9547L72.2344 28.3399H73.1607L75.4477 33.6429H74.4634L73.9568 32.4157ZM73.6456 31.6429L72.6903 29.3248L71.7422 31.6429H73.6456Z"
        fill="white"
      />
      <path
        d="M76.8652 29.1733H75.1862V28.3399H79.485V29.1733H77.806V33.6429H76.8652V29.1733Z"
        fill="white"
      />
      <path
        d="M83.9906 32.8172V33.6429H80.1911V28.3399H83.8893V29.1657H81.1319V30.5445H83.5781V31.3551H81.1319V32.8172H83.9906Z"
        fill="white"
      />
      <path
        d="M89.1238 28.3399C89.5629 28.3399 89.944 28.4157 90.2673 28.5672C90.5954 28.7187 90.8462 28.9359 91.0199 29.2187C91.1936 29.5015 91.2805 29.8374 91.2805 30.2263C91.2805 30.6101 91.1936 30.946 91.0199 31.2339C90.8462 31.5167 90.5954 31.7339 90.2673 31.8854C89.944 32.0369 89.5629 32.1126 89.1238 32.1126H87.9803V33.6429H87.0395V28.3399H89.1238ZM89.0804 31.2793C89.4905 31.2793 89.8017 31.1884 90.014 31.0066C90.2263 30.8248 90.3324 30.5647 90.3324 30.2263C90.3324 29.8879 90.2263 29.6278 90.014 29.446C89.8017 29.2642 89.4905 29.1733 89.0804 29.1733H87.9803V31.2793H89.0804Z"
        fill="white"
      />
      <path
        d="M95.5346 33.6429L94.4997 32.0899C94.4563 32.095 94.3911 32.0975 94.3043 32.0975H93.1608V33.6429H92.22V28.3399H94.3043C94.7434 28.3399 95.1245 28.4157 95.4478 28.5672C95.7759 28.7187 96.0267 28.9359 96.2004 29.2187C96.3741 29.5015 96.461 29.8374 96.461 30.2263C96.461 30.6253 96.3669 30.9687 96.1787 31.2566C95.9954 31.5445 95.73 31.7591 95.3826 31.9005L96.5478 33.6429H95.5346ZM95.5129 30.2263C95.5129 29.8879 95.4068 29.6278 95.1945 29.446C94.9822 29.2642 94.671 29.1733 94.2609 29.1733H93.1608V31.2869H94.2609C94.671 31.2869 94.9822 31.196 95.1945 31.0142C95.4068 30.8273 95.5129 30.5647 95.5129 30.2263Z"
        fill="white"
      />
      <path
        d="M99.8801 33.7187C99.3639 33.7187 98.8983 33.6025 98.4834 33.3702C98.0684 33.1328 97.7428 32.8071 97.5063 32.3929C97.2699 31.9738 97.1517 31.5066 97.1517 30.9914C97.1517 30.4763 97.2699 30.0116 97.5063 29.5975C97.7428 29.1783 98.0684 28.8525 98.4834 28.6202C98.8983 28.3828 99.3639 28.2642 99.8801 28.2642C100.396 28.2642 100.862 28.3828 101.277 28.6202C101.692 28.8525 102.017 29.1758 102.254 29.5899C102.49 30.0041 102.609 30.4712 102.609 30.9914C102.609 31.5116 102.49 31.9788 102.254 32.3929C102.017 32.8071 101.692 33.1328 101.277 33.3702C100.862 33.6025 100.396 33.7187 99.8801 33.7187ZM99.8801 32.8551C100.218 32.8551 100.522 32.7768 100.792 32.6202C101.062 32.4586 101.274 32.2364 101.429 31.9536C101.583 31.6657 101.66 31.345 101.66 30.9914C101.66 30.6379 101.583 30.3197 101.429 30.0369C101.274 29.749 101.062 29.5268 100.792 29.3702C100.522 29.2086 100.218 29.1278 99.8801 29.1278C99.5424 29.1278 99.2384 29.2086 98.9682 29.3702C98.6981 29.5268 98.4858 29.749 98.3314 30.0369C98.177 30.3197 98.0998 30.6379 98.0998 30.9914C98.0998 31.345 98.177 31.6657 98.3314 31.9536C98.4858 32.2364 98.6981 32.4586 98.9682 32.6202C99.2384 32.7768 99.5424 32.8551 99.8801 32.8551Z"
        fill="white"
      />
      <path
        d="M107.065 30.9308H107.955V33.0445C107.695 33.2616 107.391 33.4283 107.043 33.5445C106.696 33.6606 106.339 33.7187 105.972 33.7187C105.456 33.7187 104.99 33.6025 104.576 33.3702C104.161 33.1328 103.835 32.8071 103.599 32.3929C103.362 31.9788 103.244 31.5116 103.244 30.9914C103.244 30.4712 103.362 30.0041 103.599 29.5899C103.835 29.1758 104.161 28.8525 104.576 28.6202C104.995 28.3828 105.466 28.2642 105.987 28.2642C106.411 28.2642 106.797 28.3374 107.145 28.4839C107.492 28.6303 107.784 28.845 108.02 29.1278L107.427 29.7339C107.041 29.3298 106.575 29.1278 106.03 29.1278C105.673 29.1278 105.355 29.2061 105.075 29.3626C104.8 29.5192 104.583 29.7389 104.424 30.0217C104.269 30.3046 104.192 30.6278 104.192 30.9914C104.192 31.345 104.269 31.6631 104.424 31.946C104.583 32.2288 104.8 32.451 105.075 32.6126C105.355 32.7743 105.671 32.8551 106.023 32.8551C106.419 32.8551 106.766 32.7642 107.065 32.5823V30.9308Z"
        fill="white"
      />
      <path
        d="M112.511 33.6429L111.476 32.0899C111.432 32.095 111.367 32.0975 111.28 32.0975H110.137V33.6429H109.196V28.3399H111.28C111.72 28.3399 112.101 28.4157 112.424 28.5672C112.752 28.7187 113.003 28.9359 113.177 29.2187C113.35 29.5015 113.437 29.8374 113.437 30.2263C113.437 30.6253 113.343 30.9687 113.155 31.2566C112.972 31.5445 112.706 31.7591 112.359 31.9005L113.524 33.6429H112.511ZM112.489 30.2263C112.489 29.8879 112.383 29.6278 112.171 29.446C111.958 29.2642 111.647 29.1733 111.237 29.1733H110.137V31.2869H111.237C111.647 31.2869 111.958 31.196 112.171 31.0142C112.383 30.8273 112.489 30.5647 112.489 30.2263Z"
        fill="white"
      />
      <path
        d="M117.775 32.4157H115.242L114.743 33.6429H113.773L116.053 28.3399H116.979L119.266 33.6429H118.282L117.775 32.4157ZM117.464 31.6429L116.509 29.3248L115.561 31.6429H117.464Z"
        fill="white"
      />
      <path
        d="M124.564 33.6429L124.557 30.1278L122.892 33.0369H122.472L120.808 30.1733V33.6429H119.911V28.3399H120.685L122.697 31.8551L124.673 28.3399H125.447L125.454 33.6429H124.564Z"
        fill="white"
      />
      <path
        d="M19.4732 0H20.6101C24.0167 0.120102 27.3949 1.09313 30.3109 2.86616C33.6279 4.8631 36.3435 7.84326 38.0178 11.3354C39.6544 14.7186 40.2895 18.571 39.8559 22.3023C39.5129 25.3201 38.4646 28.2545 36.8188 30.8071C35.1068 33.4738 32.7516 35.7232 30.0106 37.312C27.1679 38.972 23.9089 39.8809 20.6223 40H19.5669C14.3832 39.9084 9.28493 37.6916 5.68391 33.9623C11.5241 33.9583 17.3633 33.9613 23.2035 33.9603C24.4208 33.9471 25.6554 34.0356 26.8513 33.7496C28.9043 33.3008 30.777 32.0896 32.0371 30.4092C33.2005 28.8774 33.8417 26.9608 33.8346 25.0371C33.8396 23.1389 33.2157 21.2468 32.0798 19.7262C31.2493 18.6025 30.1491 17.6865 28.9043 17.0545C29.8814 15.9094 30.4554 14.4275 30.4778 12.9211C30.5216 11.2366 29.8783 9.54911 28.7262 8.32061C27.5302 7.02086 25.7918 6.24122 24.0249 6.22595C18.8758 6.21985 13.7267 6.22697 8.57755 6.22188C8.55923 7.67532 9.52819 9.0687 10.8951 9.55827C11.29 9.71196 11.7134 9.76692 12.1338 9.78829C12.1368 12.1089 12.1358 14.4295 12.1348 16.7501C8.33429 16.0427 4.53684 15.3211 0.736328 14.6137C2.0147 9.98473 5.0376 5.87277 9.06101 3.25394C12.1399 1.22545 15.7908 0.102799 19.4732 0Z"
        fill="#FFC42E"
      />
      <path
        d="M8.5771 6.22168C13.7262 6.22677 18.8753 6.21964 24.0244 6.22575C25.7914 6.24102 27.5298 7.02066 28.7257 8.32041C29.8779 9.54891 30.5211 11.2364 30.4774 12.9209C30.455 14.4273 29.8809 15.9092 28.9038 17.0542C30.1486 17.6863 31.2489 18.6023 32.0794 19.726C33.2153 21.2466 33.8392 23.1387 33.8341 25.0369C33.8412 26.9606 33.2 28.8771 32.0366 30.409C30.7766 32.0894 28.9038 33.3006 26.8509 33.7494C25.655 34.0354 24.4204 33.9469 23.2031 33.9601C17.3629 33.9611 11.5237 33.9581 5.68346 33.9621C5.67736 33.956 5.66514 33.9448 5.65904 33.9387C10.0662 30.7316 14.4784 27.5306 18.8855 24.3224C20.9466 25.8105 22.9975 27.3128 25.0575 28.8028C24.288 26.4161 23.5074 24.0324 22.7369 21.6466C22.6636 21.5163 22.8448 21.4634 22.913 21.3932C24.8987 19.955 26.8855 18.5179 28.8611 17.0665C26.3226 17.0583 23.7842 17.0695 21.2458 17.0614C20.4611 14.6532 19.6865 12.242 18.8957 9.83593C18.0794 12.2339 17.3252 14.6532 16.5333 17.0604C11.0942 17.0654 5.65598 17.0542 0.216797 17.0583C0.337916 16.2339 0.51298 15.4166 0.735881 14.6135C4.53639 15.3209 8.33385 16.0425 12.1344 16.7499C12.1354 14.4293 12.1364 12.1087 12.1333 9.78809C11.713 9.76672 11.2896 9.71176 10.8947 9.55807C9.52774 9.0685 8.55878 7.67511 8.5771 6.22168Z"
        fill="#393683"
      />
      <path
        d="M0 19.4196C4.28193 19.3952 8.5659 19.4064 12.8478 19.4146C12.8387 19.553 12.7644 19.6222 12.628 19.6232C8.45496 20.4476 4.28397 21.2873 0.111959 22.1158C0.0590331 21.6517 0.0417303 21.1856 0 20.7204V19.4196Z"
        fill="#393683"
      />
      <path
        d="M0.572266 24.7632C4.90102 23.8166 9.21145 22.7876 13.5443 21.8604C13.6715 22.069 13.3926 22.07 13.2878 22.1484C9.3173 23.8654 5.3285 25.5387 1.357 27.2537C1.04148 26.4415 0.778881 25.609 0.572266 24.7632Z"
        fill="#393683"
      />
      <path
        d="M13.9122 24.5996C14.0639 24.5446 14.2206 24.4184 14.3896 24.5069C14.2135 24.7268 13.9621 24.8611 13.7402 25.026C10.4852 27.3405 7.22824 29.651 3.9743 31.9675C3.44809 31.2611 2.96565 30.5212 2.53613 29.7517C6.32952 28.0387 10.1219 26.3207 13.9122 24.5996Z"
        fill="#393683"
      />
      <path
        d="M16.5333 17.0606C17.3252 14.6535 18.0794 12.2341 18.8957 9.83618C19.6865 12.2423 20.4611 14.6535 21.2458 17.0616C23.7842 17.0698 26.3226 17.0586 28.8611 17.0667C26.8855 18.5181 24.8987 19.9553 22.913 21.3934C22.8448 21.4637 22.6636 21.5166 22.7369 21.6469C23.5074 24.0326 24.288 26.4163 25.0575 28.8031C22.9975 27.313 20.9466 25.8107 18.8855 24.3227C14.4784 27.5308 10.0662 30.7319 5.65903 33.939C5.05547 33.3191 4.49364 32.6596 3.97455 31.9675C7.2285 29.6509 10.4855 27.3405 13.7405 25.026C13.9623 24.8611 14.2137 24.7268 14.3898 24.5069C14.2209 24.4184 14.0641 24.5446 13.9125 24.5995C10.1221 26.3207 6.32977 28.0387 2.53639 29.7517C2.0855 28.9476 1.69262 28.112 1.35674 27.254C5.32824 25.539 9.31705 23.8657 13.2875 22.1487C13.3924 22.0703 13.6712 22.0693 13.544 21.8606C9.2112 22.7878 4.90076 23.8168 0.57201 24.7634C0.359288 23.8922 0.205598 23.0067 0.111959 22.1161C4.28397 21.2876 8.45496 20.4479 12.628 19.6235C12.7644 19.6224 12.8387 19.5532 12.8478 19.4148C8.5659 19.4067 4.28193 19.3955 0 19.4199V19.3934C0.0447837 18.6128 0.0966921 17.8311 0.216794 17.0586C5.65598 17.0545 11.0941 17.0657 16.5333 17.0606Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo