import classNames from "classnames";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react"
import Button from "../components/atoms/Button";
import ChooseBlock from "../components/ChooseBlock";
import ContactsBlock from "../components/ContactsBlock";
import Header from "../components/Header";
import OurOfferBlock from "../components/OurOfferBlock";

import '../styles/globals.scss'
import * as styles from './styles.module.scss'

const IndexPage = () => {
  const { t } = useTranslation()
  const {language} = useI18next()

  return (
    <main>
      <title>Bilbet affilate</title>
      
      <section className={styles.firstBlock}>
        <div className={styles.container}>
          <Header />
          <div className={styles.content}>
            <h1 className={classNames(styles.title, styles[language])}>
              {t("titlePart1")}
              <div className={styles.endTitle}>{t("titlePart2")}</div>
            </h1>
            <Button className={styles.startEarningBtn} href="https://partner.bilbetpartner.com/registration" text="startEarning"/>
          </div>
          <div className={styles.images}>
            <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../images/girl.png" alt="girl" />
            <StaticImage className={styles.circle1} placeholder="blurred" quality={100} src="../images/circle1.png" alt="circle1" />
            <StaticImage className={styles.circle2} placeholder="blurred" quality={100} src="../images/circle2.png" alt="circle2" />
            <StaticImage className={styles.circle3} placeholder="blurred" quality={100} src="../images/circle3.png" alt="circle3" />
            <StaticImage className={styles.circle4} placeholder="blurred" quality={100} src="../images/circle4.png" alt="circle4" />
            <StaticImage className={styles.circle5} placeholder="blurred" quality={100} src="../images/circle5.png" alt="circle5" />
          </div>
        </div>
      </section>

      <section className={styles.infoBlocks}>
        <div className={styles.container}>
          <div className={styles.ourOffer}>
            <h2 className={styles.blockTitle}>{t("ourOffer")}</h2>
            <OurOfferBlock />
            <StaticImage className={styles.imageCup} placeholder="blurred" quality={100} src="../images/image-cup.png" alt="image-cup" />
          </div>

          <div className={styles.chooseWhatYouLike}>
            <h2 className={styles.blockTitle}>{t("chooseWhatYouLike")}</h2>
            <ChooseBlock />
            <Button className={styles.becomeAPartnerBtn} href="https://partner.bilbetpartner.com/registration" text="becomeAPartner"/>
          </div>

          <footer className={styles.footer}>
            <h2 className={styles.blockTitle}>{t("contacts")}</h2>
            <ContactsBlock />
          </footer>
        </div>
      </section>
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;