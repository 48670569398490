// extracted by mini-css-extract-plugin
export var animateCircle1 = "styles-module--animateCircle1--6axXo";
export var animateCircle1Mob = "styles-module--animateCircle1Mob--R+lyM";
export var animateCircle2 = "styles-module--animateCircle2--Djn2u";
export var animateCircle2Mob = "styles-module--animateCircle2Mob--gXZq7";
export var animateCircle3 = "styles-module--animateCircle3--q49Y-";
export var animateCircle3Mob = "styles-module--animateCircle3Mob--Nu2bZ";
export var animateCircle4 = "styles-module--animateCircle4--QVC6I";
export var animateCircle4Mob = "styles-module--animateCircle4Mob--Cy5r2";
export var animateCircle5 = "styles-module--animateCircle5--JkwXo";
export var animateCircle5Mob = "styles-module--animateCircle5Mob--pQIBo";
export var becomeAPartnerBtn = "styles-module--becomeAPartnerBtn--LEer2";
export var blockTitle = "styles-module--blockTitle--FSc2f";
export var chooseWhatYouLike = "styles-module--chooseWhatYouLike--ECl-C";
export var circle1 = "styles-module--circle1--ls6xF";
export var circle2 = "styles-module--circle2--NDd+j";
export var circle3 = "styles-module--circle3--otrsS";
export var circle4 = "styles-module--circle4--yrPDT";
export var circle5 = "styles-module--circle5--5+228";
export var container = "styles-module--container--s3Y9b";
export var content = "styles-module--content--I3nnS";
export var endTitle = "styles-module--endTitle--6AO6w";
export var firstBlock = "styles-module--firstBlock--E4cIw";
export var footer = "styles-module--footer--ij+7K";
export var imageCup = "styles-module--imageCup--my5M8";
export var images = "styles-module--images--urCPP";
export var infoBlocks = "styles-module--infoBlocks--Z9-oP";
export var ourOffer = "styles-module--ourOffer--X6XJ5";
export var ru = "styles-module--ru--CTqzA";
export var startEarningBtn = "styles-module--startEarningBtn--o6CEU";
export var title = "styles-module--title--DORzR";