import * as React from "react"
import { useState } from "react"
import Button from "../atoms/Button"
import { BurgerIcon } from "../atoms/Icons"
import LanguagePicker from "../atoms/LanguagePicker"
import Link from "../atoms/Link"
import Logo from "../atoms/Logo"
import MobileMenu from "../MobileMenu"

import * as styles from './styles.module.scss'

const Header = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false)

  return (
    <div className={styles.header}>
      <Logo />
      <div className={styles.controls}>
        <LanguagePicker />

        <Link href="https://partner.bilbetpartner.com/login" text="login"/>
        <Button href="https://partner.bilbetpartner.com/registration" type="secondary" text="signUp"/>
      </div>
      <button className={styles.burger} onClick={() => setOpenMenu(true)}><BurgerIcon /></button>
      <MobileMenu open={openMenu} close={() => setOpenMenu(false)} />
    </div>
  )
}

export default Header