import classNames from "classnames"
import { useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useRef, useState } from "react"
import { CloseIcon, RusFlag, USFlag } from "../atoms/Icons"
import Logo from "../atoms/Logo"
import Link from "../atoms/Link"
import Button from "../atoms/Button"

import * as styles from './styles.module.scss'

interface IProps {
  open: boolean
  close: () => void
}

const MobileMenu = ({ open, close }: IProps) => {
  const {language, changeLanguage} = useI18next()

  const languages = [
    {
      code: 'en',
      icon: <USFlag />
    },
    {
      code: 'ru',
      icon: <RusFlag />
    }
  ]

  return (
    <div className={classNames(styles.mobileMenu, { [styles.open]: open })}>
      <div className={styles.header}>
        <Logo />
        <button className={styles.close} onClick={close}><CloseIcon /></button>
      </div>
      <div className={styles.content}>
        <Link href="https://partner.bilbetpartner.com/login" text="login"/>
        <Button href="https://partner.bilbetpartner.com/registration" type="secondary" text="signUp"/>
        <ul className={styles.languages}>
          {languages.map(lng => (
            <a
              className={classNames(styles.language, {[styles.active]: lng.code === language})}
              onClick={() => {
                changeLanguage(lng.code)
              }}>
                {lng.icon}
                {lng.code}
            </a>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default MobileMenu